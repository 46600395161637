import React from 'react'
import { Container, } from 'react-bootstrap'
import * as classes from '../custom-styles/ContactUs.module.css'
import USA from '../images/USA.svg'
import UK from '../images/UK.svg'
import styled from 'styled-components'

const USAImage = styled(USA)`
  height: 50px;
  width: 100%;
`;

const UKImage = styled(UK)`
  height: 50px;
  width: 100%;
`;

const ContactUs = () => {
  return (
    <>
      <Container>
        <div className='text-center'>
          <h1 className={`${classes.name} my-4`}>Contact Us</h1>
        </div>
      </Container>

      <div className={`${classes.w100} d-flex`}>
        <div className={`${classes.w50}`}>
          <div className=''>
            <div className={`${classes.addressadd} d-flex my-4`}>
              <div className='px-3'>
              <USAImage />
              </div>
              <div className=''>
                <h4>2010 El Camino Real # 1268</h4>
                <h4>Santa Clara</h4>
                <h4>CA 95050 </h4>
                <h4>USA </h4>
              </div>
            </div>

          </div>
        </div>
        <div className={`${classes.w50}`}>
          <div className={`${classes.addressadd} d-flex my-4`}>
            <div className='px-3'>
            <UKImage />
            </div>
            <div className=''>
              <h4>7 Bell Yard</h4>
              <h4>London</h4>
              <h4>WC2A 2JR </h4>
              <h4>United Kingdom </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs